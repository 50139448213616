import {
	CustomizerElement,
	isImageElement,
	isTextElement,
	isTextArtElement,
	isShapeElement,
} from '../components/interfaces';
import { useZakekeProfanityFilter, useZakekeHelpers } from '@zakeke/zakeke-customizer-react';

import { useAppStore, useUpdateItem } from '../state/store';
import { atom, useAtom } from 'jotai';
import { groupBy } from 'lodash';
import { useCallback } from 'react';

const isCheckingForProfanityAtom = atom<boolean>(false);

const useIsCheckingForProfanity = () => {
	return useAtom(isCheckingForProfanityAtom);
};

const useCustomizerElementToProfanityItem = () => {
	const { getImage } = useZakekeHelpers();
	return useCallback(
		async (item: CustomizerElement): Promise<ProfanityItem | undefined> => {
			if (isImageElement(item)) {
				const image = await getImage(item.imageId);
				return {
					dataToCheck: image.previewUrl,
					itemId: image.imageID.toString(),
					type: 'image',
				};
			}
			if (isShapeElement(item) && item.filledImageId) {
				const image = await getImage(item.filledImageId);
				return {
					dataToCheck: image.previewUrl,
					itemId: image.imageID.toString(),
					type: 'image',
				};
			}
			if (isTextElement(item)) {
				return {
					dataToCheck: item.content,
					itemId: item.guid,
					type: 'text',
				};
			}
			if (isTextArtElement(item)) {
				return {
					dataToCheck: item.content,
					itemId: item.guid,
					type: 'text',
				};
			}
			return undefined;
		},
		[getImage],
	);
};

type ProfanityItem = {
	dataToCheck: string;
	itemId: string;
	type: 'text' | 'image';
};
const useProfanityFilter = () => {
	const sidesPrintMethods = useAppStore((store) => store.sidesPrintMethods);
	const { checkForProfanity } = useZakekeProfanityFilter();
	const updateItem = useUpdateItem();
	const customizerElementToProfanityItem = useCustomizerElementToProfanityItem();
	const [isCheckingForProfanity, setIsCheckingForProfanity] = useIsCheckingForProfanity();

	const checkItemsForProfanity = useCallback(
		async (items: CustomizerElement[]) => {
			try {
				setIsCheckingForProfanity(true);

				const getItemsForFilter = async (items: CustomizerElement[]) =>
					Promise.all(items.map(customizerElementToProfanityItem)).then(
						(items) => items.filter((item) => item !== undefined) as ProfanityItem[],
					);

				const getProfanityFilteredItems = async (items: CustomizerElement[]) => {
					const itemsGroups = Object.entries(groupBy(items, 'sideId')).map(async ([sideId, sideItems]) => ({
						printMethodId: sidesPrintMethods.get(Number(sideId))!,
						items: await getItemsForFilter(sideItems),
					}));

					return Promise.all(itemsGroups).then(checkForProfanity);
				};

				const profanityResult = await getProfanityFilteredItems(items);

				if (!profanityResult) {
					setIsCheckingForProfanity(false);
					return [];
				}

				if (!profanityResult.length) {
					setIsCheckingForProfanity(false);
					return [];
				}

				// convert profanity result to items with profanity
				// cause itemId for image and shape is the imageId and not the item guid
				const itemsWithProfanity = items
					.filter((item) => {
						if (isTextElement(item) || isTextArtElement(item)) {
							return profanityResult.some((profanity) => profanity.itemId === item.guid);
						}
						if (isImageElement(item)) {
							return profanityResult.some((profanity) => profanity.itemId === item.imageId.toString());
						}
						if (isShapeElement(item) && item.filledImageId) {
							return profanityResult.some(
								(profanity) => profanity.itemId === item.filledImageId?.toString(),
							);
						}
						return false;
					})
					.map((item) => ({
						itemId: item.guid,
					}));

				for (const profanityItem of itemsWithProfanity) {
					updateItem(profanityItem.itemId, (item) => {
						if (isTextElement(item) || isTextArtElement(item)) {
							return { content: 'XXXXXXXX' };
						}
						// in case of images or filled fillable shapes do nothing
						return item;
					});
				}
				setIsCheckingForProfanity(false);

				return itemsWithProfanity;
			} catch (error) {
				setIsCheckingForProfanity(false);
				throw error;
			}
		},
		[checkForProfanity, customizerElementToProfanityItem, setIsCheckingForProfanity, sidesPrintMethods, updateItem],
	);

	return {
		checkItemsForProfanity,
		isCheckingForProfanity,
	};
};

export default useProfanityFilter;
